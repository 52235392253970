/**
 * @file   src\config\axiosConfig.ts
 * @brief  This file is responsible for axios client configurations.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

// import axios from 'axios';
// import { STATUS_401 } from '../utils/constants';
// import { getRefreshToken } from '../utils/localStorage';



// // Craete axios instance.
// export const axiosClient = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
// });

// axiosClient.interceptors.request.use(
//   async (config) => {
//     const token = '';
//     config.headers['x-session-token'] = token;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error); // Add a return statement here
//   },
// );

// axiosClient?.interceptors?.request?.use(
//   (config) => {
//     const accessToken = localStorage?.getItem('accessToken');
//     if (accessToken) {
//       config.headers.Authorization = accessToken;
//     }
//     return config;
//   },
//   (err) => {
//     return Promise.reject(err);
//   },
// );

// // Handle interceptor response.
// axiosClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response?.status === STATUS_401) {
//       // localStorage.clear();
//       // // window.location.href = `${process.env.REACT_APP_SITE_URL}?type=snout`;
//       // window.location.href = `${process.env.REACT_APP_SITE_URL}`;

//       getNewToken();
//     }
//     return Promise.reject(error);
//   },
// );

// const getNewToken = async () => {
//   const  payloadtkn  =  getRefreshToken();
//   console.log("payload", payloadtkn);
//   // if (payload.status === 200) {
//   //   console.log("data", payload.data);
//   //   // window.open(payload.data.URL, "_blank");
//   //   localStorage.setItem('userAuth', JSON.stringify(payload.data));
//   // } else {
//   //   // toast.error(payload.data.error);
//   // }

//   const payload = {
//     token: payloadtkn,
//   };
//   const queryParams = new URLSearchParams(payload).toString();
  
//   const base_url = 'https://api.pawsqv.com/adminapi/refreshtoken';
//   // const base_url = 'https://staging-api.pawsqv.com/adminapi/refreshtoken';
//   // const base_url = 'https://petalert-api.zcodemo.com/adminapi/refreshtoken';
//   const APIKey = "022258244a370470853351f0b03ef71b";

//   fetch(`${base_url}?${queryParams}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'APIKey': APIKey
//     },
//   })
//     .then(response => response.json())
//     .then(data => {
//       console.log(data);
//       if(data == null){
//         localStorage.clear();
//         // window.location.href = `${process.env.REACT_APP_SITE_URL}?type=snout`;
//         window.location.href = `${process.env.REACT_APP_SITE_URL}`;
//       }
//       else{
//         let value = data;
//         localStorage.removeItem('userAuth');
//         localStorage.setItem('userAuth', JSON.stringify(value));
//       }
//     })
//     .catch(error => {
//     });
// }




import axios from 'axios';
import { STATUS_401 } from '../utils/constants';
import { getRefreshToken } from '../utils/localStorage';

// Create axios instance.
export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosClient.interceptors.request.use(
  async (config) => {
    const token = '';
    config.headers['x-session-token'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage?.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Handle interceptor response.
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === STATUS_401) {
      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function(resolve, reject) {
            failedQueue.push({ resolve, reject });
          }).then(token => {
            if (token) {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
            }
            return axios(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const token = await getNewToken();
          if (token) {
            axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
          }
          processQueue(null, token);
          return axios(originalRequest);
        } catch (err) {
          processQueue(err, null);
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }
    }
    return Promise.reject(error);
  },
);

const getNewToken = async (): Promise<string | null> => {
  const payloadtkn = getRefreshToken();
  console.log("payload", payloadtkn);

  const payload = {
    token: payloadtkn,
  };
  const queryParams = new URLSearchParams(payload).toString();
  const base_url = 'https://api.pawsqv.com/adminapi/refreshtoken';
  // const base_url = 'https://staging-api.pawsqv.com/adminapi/refreshtoken';
  // const base_url = 'https://petalert-api.zcodemo.com/adminapi/refreshtoken';
  const APIKey = "022258244a370470853351f0b03ef71b";

  try {
    const response = await fetch(`${base_url}?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'APIKey': APIKey
      },
    });

    const data = await response.json();
    if (response.status !== 200 || data == null) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_SITE_URL}`;
      return null;
    } else {
      let value = data;
      localStorage.setItem('userAuth', JSON.stringify(value));
      return value.accessToken; // Assuming the new token is stored here
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_SITE_URL}`;
    return null;
  }
}
