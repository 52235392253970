/**
 * @file   src\containers\ManageUser.tsx
 * @brief  Product listing page
 * @date December, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useEffect, useState, KeyboardEvent } from 'react';
import { Container, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Strings from '../assets/strings/Strings.json';
import IcSearch from '../assets/img/icons/Search';
import IcView from '../assets/img/icons/View';
import IcDelete from '../assets/img/icons/Delete';
import IcBell from '../assets/img/icons/BellModel';
import IcDeleteModal from '../assets/img/icons/DeleteModel';
import PAInput from '../components/PAInput';
import PASelect from '../components/PASelect';
import { SetActivemenuToLocal } from '../utils/localStorage';
import { useAppDispatch, useAppSelector } from '../hooks';
import { UserListRes, UserListResponse } from '../interfaces/userInterface';
import { deleteuser, updatewellnessdata, userList } from '../store/actions/userAction';
import PaginationList from '../components/PaginationList';
import HomeLoader from '../components/loader/homeLoader';
import { changeLoaderStatus } from '../store/actions/generalAction';

const ManageUsers = () => {
  const LoaderData = useAppSelector((RootReducer) => RootReducer.general.loaderState.loader);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [thisLoading, setThisLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modal2Show, setModal2Show] = useState(false);
  const Modal2Close = () => setModal2Show(false);
  const Modal2Show = () => setModal2Show(true);
  const [searchText, setSearchText] = useState('');
  const [userListRes, setUserListRes] = useState<UserListResponse>();
  const [active, setActive] = useState(1);
  const [data, setData] = useState<UserListRes>();
  const [selectedValues, setSelectedValues] = useState<{ value: string; label: string }>();
  const [currentLPDStatus, setCurrentLPDStatus] = useState(false);
  const [selectedWellnessId, setSelectedWellnessId] = useState<number>();
  const [modal3Show, setModal3Show] = useState(false);
  const Modal3Close = () => setModal3Show(false);
  const Modal3Show = () => setModal3Show(true);
  const [modal4Show, setModal4Show] = useState(false);
  const Modal4Close = () => setModal4Show(false);
  const Modal4Show = () => setModal4Show(true);
  const [selectedUser, setSelectedUser] = useState<number>();

  const GetUserList = async () => {
    setThisLoading(true);
    const funReq = {
      page: {
        // Size: 10,
        // orderby: '',
        pageno: active,
      },
      searchkey: searchText,
    };
    try {
      const { payload } = await dispatch(userList(funReq) as any);
      if (payload.status === 200) {
        const userList = payload.data.userlist;
        if (userList && userList.length > 0) {
          userList.map((item: any) => {
            let label: string | null;

            switch (item.alertoverrideid) {
              case 1:
                label = "Enabled";
                break;
              case 2:
                label = "Vacation Mode";
                break;
              case 3:
                label = "Stopped";
                break;
              case null:
                label = "Select";
                break;
              default:
                label = null;
            }

            item.alertoverrideid = {
              value: item.alertoverrideid ?? 0,
              label: label,
            };
          });
        }
        setData(userList);
        setUserListRes(payload.data);
      } else {
        toast.error(payload.data.error);
      }
    } catch (error) {
      return false;
    }
    dispatch(changeLoaderStatus(false) as any);
    return undefined;
  };

  useEffect(() => {
    GetUserList();
  }, [active, searchText]);

  useEffect(() => {
    GetUserList();
  }, []);

  const options = [
    { value: 1, label: 'Enabled' },
    { value: 2, label: 'Vacation Mode' },
    { value: 3, label: 'Stopped' },
  ];

  const handleChange = (e: { target: { value: string } }) => {
    setSearchText(e.target.value);
  };

  const handleOptionClick = (e: { value: string; label: string }, wellnessid: number | undefined) => {
    if (wellnessid !== undefined) {
      setSelectedValues(e);
      setSelectedWellnessId(wellnessid);
      Modal2Show();
    }
  };

  const sendAPI = async (funReq: any) => {
    try {
      const { payload } = await dispatch(updatewellnessdata(funReq) as any);
      if (payload.status === 200) {
        toast.success(payload.data.msg);
        setCurrentLPDStatus(false);
        GetUserList();
      } else {
        if (payload.code === 500 || payload === null) {
          toast.error(Strings.UserDetails.SubmitError);
        }
        else {
          toast.error(payload.error)
        }
      }
    } catch (error) {
      return false;
    }
    return undefined;
  };

  const naviGateToDetail = (userId: number) => {
    SetActivemenuToLocal('user');
    navigate('/userdetails', { state: { userId } });
    dispatch(changeLoaderStatus(true) as any);
  };

  const handleDeleteConfirm = (userId: number) => {
    setSelectedUser(userId);
    Modal3Show();
  };

  const sendDeleteRequest = async () => {
    let funReq;
    if (selectedUser) {
      funReq = {
        userid: selectedUser,
      };
      try {
        const { payload } = await dispatch(deleteuser(funReq) as any);
        if (payload.status === 200) {
          Modal4Show();
          setSelectedUser(0);
          GetUserList();
        } else {
          toast.error(payload.data.error);
        }
      } catch (error) {
        return false;
      }
    }
    return undefined;
  };

  const handleDelete = () => {
    Modal3Close();
    sendDeleteRequest();
  };

  const ChangeAlertStatus = () => {
    let funReq;
    if (selectedValues && selectedWellnessId) {
      funReq = {
        alertoverrideid: selectedValues?.value,
        notifylpd: null,
        wellnessid: selectedWellnessId,
      };
    }
    sendAPI(funReq);
    Modal2Close();
  };

  const handleNotifyLPDStatus = (status: boolean, wellnessid: number | undefined) => {
    if (wellnessid !== undefined) {
      setCurrentLPDStatus(status);
      setSelectedWellnessId(wellnessid);
      handleShow();
    }
  };

  const changeLPDStatus = () => {
    const funReq = {
      alertoverrideid: null,
      notifylpd: currentLPDStatus === null ? true : !currentLPDStatus,
      wellnessid: selectedWellnessId,
    };
    sendAPI(funReq);
    handleClose();
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      GetUserList();
    }
  };

  return (
    <>
      {LoaderData ? <HomeLoader /> :
        <div className="layout-content">
          <Container fluid className="content-wrap">
            <Row className="align-items-center header mb-3">
              <Col>
                <h2>{Strings.ManageUsers.heading}</h2>
              </Col>
              <Col lg="auto">
                <div className="search">
                  <Button id="button-addon1">
                    <IcSearch />
                  </Button>
                  <PAInput id="search" name="search" type="text" onKeyDown={handleKeyPress} onChange={handleChange} value={searchText} placeholder={Strings.ManageUsers.Search} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="min-height">
                  <Table responsive className={data !== undefined && data.length > 0 ? 'cstm-tble' : 'cstm-tble border-0'}>
                    <thead>
                      <tr>
                        <th>{Strings.Table.SerialNo}</th>
                        <th>{Strings.Table.Name}</th>
                        <th>{Strings.Table.Phone}</th>
                        <th>{Strings.Table.Email}</th>
                        <th>{Strings.Table.IceStatus}</th>
                        <th>{Strings.Table.AlertOverRide}</th>
                        <th><center>{Strings.Table.NotifyLPD}</center></th>
                        <th><center>{Strings.Table.Actions}</center></th>
                      </tr>
                    </thead>
                    {data !== undefined && data.length > 0 ? (
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>
                              <span>{item.name}</span>
                            </td>
                            <td>{item.phone}</td>
                            <td className='usertd' title={item.email}>{item.email}</td>
                            <td>{item.icestatus === 3 ? "Approved" : "Pending"}</td>
                            <td>
                              <PASelect value={item.alertoverrideid} options={options} onChange={(e: { value: string; label: string }) => handleOptionClick(e, item.wellnessid)} />
                            </td>
                            <td><center>
                              <Button disabled={item?.lpdphone == "" || item?.lpdphone == null ? true : false} variant={item.notifylpd === true ? 'secondary on' : 'secondary off'} onClick={() => handleNotifyLPDStatus(item.notifylpd, item.wellnessid)}>
                                {item.notifylpd === true ? 'On' : 'Off'}
                              </Button></center>
                            </td>
                            <td className="nowrap"><center>
                              <Button variant="primary view" onClick={() => naviGateToDetail(item.id)} title='View'>
                                <IcView />
                              </Button>
                              <Button variant="primary delete" onClick={() => handleDeleteConfirm(item.id)} title="Delete">
                                <IcDelete />
                              </Button></center>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="noResultDiv" colSpan={7}>
                            <center>
                              <p>{Strings.Table.NoResults}</p>
                            </center>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
                {userListRes?.totalrecordcount !== 0 && (
                  <div className="cstm-pagenation">
                    <PaginationList count={userListRes?.totalrecordcount} active={active} limit={10} setActive={setActive} />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Modal size="lg" className="cstmmodel" show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="icon d-flex justify-content-center">
                {' '}
                <IcBell />
              </div>
              <h1 className="text-center">{Strings.NotifyLPDModal.heading}</h1>
              <p className="text-center">{Strings.NotifyLPDModal.Paragraph}</p>
              <div className="buttons mt-4 text-center">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={changeLPDStatus}>
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal size="lg" className="cstmmodel" show={modal2Show} onHide={Modal2Close} centered backdrop="static">
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="icon d-flex justify-content-center">
                {' '}
                <IcBell />
              </div>
              <h1 className="text-center">{Strings.AlertOverrideModal.heading}</h1>
              <p className="text-center">{Strings.AlertOverrideModal.Paragraph}</p>
              <div className="buttons mt-4 text-center">
                <Button variant="secondary" onClick={Modal2Close}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={ChangeAlertStatus}>
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal size="xl" className="cstmmodel" show={modal3Show} onHide={Modal3Close} centered backdrop="static">
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="icon d-flex justify-content-center">
                <IcDeleteModal />
              </div>
              <h2 className="text-center mb-4">{Strings.DeleteModal1.heading}</h2>
              <div className="bg-box">
                <h3>{Strings.DeleteModal1.subheading}</h3>
                <p>{Strings.DeleteModal1.Paragraph1}</p>
                <p>{Strings.DeleteModal1.Paragraph2}</p>
                <p>{Strings.DeleteModal1.Paragraph3}</p>
                <p>{Strings.DeleteModal1.Paragraph5}</p>
              </div>
              <p className="text-center mt-4 mb-1 text-medium">{Strings.DeleteModal1.Paragraph4}</p>
              <div className="buttons mt-3 text-center">
                <Button variant="primary" onClick={handleDelete}>
                  Delete Account
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal size="lg" className="cstmmodel" show={modal4Show} onHide={Modal4Close} centered backdrop="static">
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="icon d-flex justify-content-center">
                <IcDeleteModal />
              </div>
              <h1 className="text-center">{Strings.DeleteModal2.heading}</h1>
              <p className="text-center">{Strings.DeleteModal2.Paragraph}</p>
            </Modal.Body>
          </Modal>
        </div>
      }
    </>
  );
};

export default ManageUsers;
